<template>
    <div>
        <a-config-provider :locale="zhCN">
            <a-card>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <a-button type="primary" @click="exportFormClick">
                            <a-icon type="download" />
                            <span>导出报表</span>
                        </a-button>
                    </a-col>
                    <a-col>
                        <a-date-picker
                            style="width: 130px"
                            format="YYYY-MM-DD"
                            v-model="pickerTime"
                            @change="changePickerTime"
                        />
                    </a-col>
                    <a-col>
                        <a-tree-select
                            style="width: 140px"
                            @change="handleCompanyChange"
                            allowClear
                            :placeholder="l('Company')"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            tree-default-expand-all
                            v-model="comPanyId"
                        />
                    </a-col>
                    <a-col>
                        <a-input-search
                            v-model.trim="filterText"
                            placeholder="请输入搜索内容..."
                            style="width: 200px"
                            enter-button
                            @search="searchChange"
                        />
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 32]">
                    <a-col>
                        <a-spin :spinning="spinning">
                            <a-table
                                :scroll="{ y: scroll_y }"
                                @change="sorterChange"
                                :columns="columns"
                                :pagination="false"
                                :rowKey="(data) => data.id"
                                :dataSource="tableData"
                            >
                            </a-table>
                        </a-spin>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-col>
                            <a-pagination
                                class="pagination"
                                :total="totalItems"
                                v-model="pageNumber"
                                showSizeChanger
                                showQuickJumper
                                :showTotal="showTotalFun"
                                @change="onChangePage"
                                @showSizeChange="showSizeChange"
                            />
                        </a-col>
                    </a-col>
                </a-row>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
    CompanyServiceProxy,
    CustomizeReportServiceServiceProxy,
    LeaveRecordServiceProxy,
} from '@/shared/service-proxies';
import { fileDownloadService } from '@/shared/utils';
import moment from 'moment';
import { abpService } from '@/shared/abp';

export default {
    name: 'day-sum',
    mixins: [AppComponentBase],

    data() {
        return {
            spinning: false, //加载中
            //表格上方间隔
            defaultTop: 90,
            zhCN,
            companyTreeList: [], //公司
            comPanyId: undefined, //公司id
            departmentId: undefined,

            filterText: '', //搜索
            pickerTime: moment(), //

            // 用户表格
            columns: [
                {
                    title: '公司',
                    dataIndex: 'company',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'company' },
                },
                {
                    title: '部门',
                    dataIndex: 'department',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'department' },
                },
                {
                    title: '计划出勤人数',
                    dataIndex: 'planPeopleNum',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'planPeopleNum' },
                },
                {
                    title: '实际人数',
                    dataIndex: 'actualPeopleNum',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'actualPeopleNum' },
                },
                {
                    title: '人数差异',
                    dataIndex: 'peopleNumDifference',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'peopleNumDifference' },
                },
                {
                    title: '计划出勤时数',
                    dataIndex: 'planLobor',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'planLobor' },
                },
                {
                    title: '实际出勤时数',
                    dataIndex: 'actualLobor',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'actualLobor' },
                },
                {
                    title: '时数差异',
                    dataIndex: 'loborDifference',
                    sorter: true,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'loborDifference' },
                },
            ],
            tableData: [],
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40'],
            request: { maxResultCount: 10, skipCount: 0, sorting: '' },

            _CompanyConfigServiceProxy: undefined, //公司请求
            _fileDownloadService: undefined, //导出报表
            _reportService: undefined,
        };
    },
    mounted() {
        // 公司
        this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        this._reportService = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
        this.initCompany();
        if (abpService.abp.userInfo.companyId) {
            this.comPanyId = abpService.abp.userInfo.companyId;
        }
        this.getData();
    },
    methods: {
        searchChange() {
            this.request.skipCount = 0;
            this.pageNumber = 1;
            this.getData();
        },
        changePickerTime() {
            this.request.skipCount = 0;
            this.pageNumber = 1;
            this.getData();
        },
        // 公司选择
        handleCompanyChange(value) {
            this.comPanyId = value;
            this.request.skipCount = 0;
            this.pageNumber = 1;
            this.getData();
        },

        //初始化公司
        initCompany() {
            this._CompanyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    // this.spinning = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },

        getData() {
            this.spinning = true;
            this._reportService
                .getRealTimeAttendanceDay(
                    this.pickerTime,
                    this.comPanyId || undefined,
                    this.filterText || undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .then((res) => {
                    this.spinning = false;
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },

        exportFormClick() {
            this._reportService
                .getRealTimeAttendanceDayExport(
                    this.pickerTime,
                    this.comPanyId || undefined,
                    this.filterText || undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.selectedRowKeys = [];
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.selectedRowKeys = [];
            // this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.searchChange();
        },
        /**
         * 排序
         * @param pagination
         * @param filters
         * @param sorter
         */
        sorterChange(pagination, filters, sorter) {
            if (sorter.order) {
                this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
            } else {
                this.request.sorting = undefined;
            }
            this.searchChange();
        },
    },
};
</script>

<style scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
